const initToggling = () => {
  const buttons = document.querySelectorAll('.button-toggle')
  buttons.forEach((butt) => {
    butt.addEventListener('click',  () => {
      if (butt.querySelector('.toggled')) {
        butt.querySelector('.to-toggle').classList.remove('toggled')
      } else {
        if (document.querySelector('.toggled')) {
          document.querySelector('.toggled').classList.remove('toggled')
        }
        butt.querySelector('.to-toggle').classList.toggle('toggled')
      }
    })
  })
}

export {initToggling}
