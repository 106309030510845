const initCustomTooltip = () => {
  const tooltips = document.querySelectorAll('.custom-tooltip');

  if (tooltips) {
    if (window.innerWidth < 700) {
      tooltips.forEach( tooltip => {
        tooltip.classList.add('mobile-custom-tooltip')
        tooltip.classList.remove('custom-tooltip')
      });
    }
    window.onmousemove = function (e) {
      let x = (e.clientX ) + 'px',
          y = (e.clientY - 60) + 'px';
      for (let i = 0; i < tooltips.length; i++) {
          tooltips[i].style.top = y;
          tooltips[i].style.left = x;
      }
    };
  }
}

export {initCustomTooltip};
