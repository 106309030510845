import Flickity from 'flickity/js/index'

const initFlickity = () => {
  if (document.querySelector('.is_this_art')) {
    const elem = document.querySelector('.is_this_art');

    const flkty = new Flickity( elem, {
      cellAlign: 'left',
      wrapAround: true,
      draggable: true,
      lazyLoad: true,
      pageDots: false,
      cellSelector: '.carousel-cell'
    });
  }
}

export {initFlickity} ;
