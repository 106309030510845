const initCopyLink = () => {
  const links = document.querySelectorAll(".sharing-link")

  const copyToClipboard = () => {
    let str = event.target.attributes[1].value;
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    event.target.insertAdjacentHTML('beforebegin', `link copied !`)
  };

  if (links) {
    links.forEach( link => {
      link.addEventListener("click", copyToClipboard);
    })
  }
}

export {initCopyLink};


// const initCustomTooltip = () => {
//   const tooltips = document.querySelectorAll('.custom-tooltip');

//   if (tooltips) {
//     if (window.innerWidth < 700) {
//       tooltips.forEach( tooltip => {
//         tooltip.classList.add('mobile-custom-tooltip')
//         tooltip.classList.remove('custom-tooltip')
//       });
//     }
//     window.onmousemove = function (e) {
//       let x = (e.clientX ) + 'px',
//           y = (e.clientY - 60) + 'px';
//       for (let i = 0; i < tooltips.length; i++) {
//           tooltips[i].style.top = y;
//           tooltips[i].style.left = x;
//       }
//     };
//   }
// }

